function activiteController($translate, $state, $scope, WS, $window, DatatableLang) {
    'ngInject';
    const vm = this;
    vm.activites = {};

    vm.get = function() {
        WS.get('activites', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.activites = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



    vm.update = function(activite) {
        //Sconsole.log(activite);
        WS.put('activites/' + activite.id, activite)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succés'),
                        $translate.instant('activite modifiée avec succés'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cette activite!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('activites/' + id).then(
                function() {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('activite Supprimée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer cette activite'),
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }



         vm.sortColumn = 'code';   
         vm.reverse = false;   

        vm.sortBy = function(column) {
            if (vm.sortColumn === column) {
                vm.reverse = !vm.reverse;  // Inverser le tri si on clique sur la même colonne
            } else {
                vm.sortColumn = column;
                vm.reverse = false;  // Réinitialiser en ascendant
            }
        };


    vm.ajouter = function(activite) {
        WS.post('activites', activite)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('activite ajoutée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'activiteController',
    fn: activiteController
};