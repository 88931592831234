function integrationerpController(AppSettings, $state, $scope, WS, $uibModal, $parse, Flash, $window, $translate) {
  'ngInject';
  const vm = this;


  vm.indexTable = 0;
  vm.disabled_buttons = true;


  vm.windowPassword = $uibModal.open({
    animation: false,
    templateUrl: 'myModalContent.html',
    size: 'lg',
    backdrop: 'static',
    controller: function ($scope, $translate) {
      $scope.message_password = $translate.instant('confirmer le mot de passe de Controlleur');

      $scope.verifPassword = function () {
        if ($scope.set_password != '' && $scope.set_password != undefined) {
          var data = {
            password: $scope.set_password
          };
          console.log(data);
          WS.post('erp/checkPassword', data).then(
            function (response) {
              if (response.data.success) {
                swal(
                  $translate.instant('Succès'),
                  $translate.instant('Mot de passe Confirmé'),
                  'success'
                );
                vm.windowPassword.close();
                vm.disabled_buttons = false;

              } else {
                swal(
                  $translate.instant('Attention'),
                  $translate.instant(response.data.message),
                  'warning'
                );
              }

            },
            function () {
              swal(
                $translate.instant('Attention'),
                $translate.instant('erreur de chargement des données!!'),
                'warning'
              );
            }
          );
        } else {
          swal(
            $translate.instant('Attention'),
            $translate.instant('Mot de passe Obligatoire'),
            'warning'
          );
        }
      }
    }
  });


  $scope.moveCSV = function (element) {
    var reader = new FileReader();

    reader.onload = function (event) {
      vm.csvfile.filetxt = event.target.result;
      vm.files = element.files;
    }
    //reader.readAsText(vm.files[0], 'Windows-1256');

    reader.readAsDataURL(element.files[0]);
    $scope.$apply();
  };

  /**
  * Wavesoft
  */

  vm.waveSoft_bl_avoir = function () {
    var url = 'erp/getByDateExport/wavesoft/bl';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.waveSoft_commande = function () {
    var url = 'erp/getByDateExport/wavesoft/commande';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.wavesoft_reglement = function () {
    var url = 'erp/getByDateExport/wavesoft_reglement';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.wavesoft_mouvement = function () {
    var url = 'erp/getByDateExport/wavesoft_mouvement';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.importWavesoftMouvement = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('erp/importWavesoftMouvement', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };

  vm.importWavesoftClients = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('erp/importWavesoftClients', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'success'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
        if (response.data.error == true) {
          swal(
            'Warning',
            '',
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };

  vm.exportWavesoftClients = function () {
    $window.location.href = AppSettings.apiUrl + 'erp/exportWavesoftClients';
  }

  /**
  * foxpro
  */

  vm.foxpro_exportExcelEntete_bl = function () {
    var url = 'erp/foxpro/foxpro_entete_bl';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.foxpro_exportExcelEntete_bl_details = function () {
    var url = 'erp/foxpro/foxpro_entete_bl_details';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.foxpro_exportExcelDetailReg = function () {
    var url = 'erp/foxpro/foxpro_exportExcelDetailReg';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.foxpro_exportExcelChargements = function () {
    var url = 'erp/foxpro/foxpro_exportExcelChargement';
    $window.location.href = AppSettings.apiUrl + url;

  };

  vm.foxpro_importExcelMouvement = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('erp/foxpro/foxpro_importExcelMouvement', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };



  vm.WINSYSImportMouvement = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('erp/WINSYSImportMouvement', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };



  vm.selectUsers = function () {
    WS.get('users/selectUsers', vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.selectusers = response.data;
          vm.selectusersfiltred = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.GSCOMImportClients = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('erpgscom/GSCOMImportClients', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };


  /**
   * Sage
   */



  vm.SageExportBlAvoir = function () {
    var url = 'erp/sage/SageExportBlAvoir';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.SageExportBlAvoirTGT = function () {
    var url = 'erp/sage/SageExportBlAvoirTGT';
    $window.location.href = AppSettings.apiUrl + url;

  };
  vm.SageExportCommandeSATEM = function () {
    var url = 'erp/sage/SageExportCommandeSATEM';
    $window.location.href = AppSettings.apiUrl + url;

  };

  vm.SageExportCommandeWinox = function () {
    var url = 'erp/sage/SageExportCommandeWinox';
    $window.location.href = AppSettings.apiUrl + url;

  };

  vm.SageExportClients = function () {
    var url = 'erp/sage/SageExportClients';
    $window.location.href = AppSettings.apiUrl + url;

  };

  vm.SageExportBlAvoirNatilait = function (type) {
    var url = 'erpnatilait/ExportCommandes/' + type;
    $window.location.href = AppSettings.apiUrl + url;
  }

  vm.SageExportEnteteRMS = function () {

    var url = 'erprms/exportentete/' + vm.rms.date_debut + '/' + vm.rms.date_fin;
    $window.location.href = AppSettings.apiUrl + url;
  }
  vm.SageExportLigneRMS = function () {
    var url = 'erprms/exportlignes/' + vm.rms.date_debut + '/' + vm.rms.date_fin;
    $window.location.href = AppSettings.apiUrl + url;
  }

  vm.SageExportMouvementRMS = function (type) {
    var url = 'erprms/exportmouvements/' + vm.rms.date_debut + '/' + vm.rms.date_fin;;
    $window.location.href = AppSettings.apiUrl + url;
  }

  vm.SageExportClientsRMS = function (type) {
    var url = 'erprms/exportclients/' + vm.rms.date_debut + '/' + vm.rms.date_fin;;
    $window.location.href = AppSettings.apiUrl + url;
  }

  vm.SageExportReglementRMS = function (type) {
    var url = 'erprms/exportReglementRms/' + vm.rms.date_debut + '/' + vm.rms.date_fin;;
    $window.location.href = AppSettings.apiUrl + url;
  }



  /**
   * GeSCOM
   */
  vm.SageExportReglementGsCom = function () {
    var url = 'erpgscom/SageExportReglementGsCom/' + vm.gscom.date_debut + '/' + vm.gscom.date_fin+ '/' + vm.gscom.user_code;
    $window.location.href = AppSettings.apiUrl + url;
  }

  vm.exportClientsGscom = function () {
    var url = 'erpgscom/exportClientsGscom/' + vm.gscom.date_debut + '/' + vm.gscom.date_fin;;
    $window.location.href = AppSettings.apiUrl + url;
  }

  vm.exportMouvementsClientsGscom = function () {
    var url = 'erpgscom/exportMouvementsClientsGscom/' + vm.gscom.date_debut + '/' + vm.gscom.date_fin;
    $window.location.href = AppSettings.apiUrl + url;
  };
  vm.exportEnteteGescom = () => {
    var url = 'erpgscom/exportEnteteGescom/' + vm.gscom.date_debut + '/' + vm.gscom.date_fin+ '/' + vm.gscom.user_code;
    $window.location.href = AppSettings.apiUrl + url;
  }

  vm.exportLigneGescom = () => {
    var url = 'erpgscom/exportLigneGescom/' + vm.gscom.date_debut + '/' + vm.gscom.date_fin+ '/' + vm.gscom.user_code;
    $window.location.href = AppSettings.apiUrl + url;
  }


  vm.getFirstSociete = function () {
    WS.get('societes/getFirstSociete').then(
      function (response) {
        vm.societe = response.data;
        $scope.$apply();
      },
      function (error) {
        console.log('error : ->', error);
      }
    );
  };




  /**
   * Navision export
   */
  vm.navisionExportVente = function (type) {
    var url = 'navisionErp/exportVente/' + type;
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.navisionExportClients = function () {
    var url = 'navisionErp/exportClients';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.navisionExportMouvements = function () {
    var url = 'navisionErp/exportMouvements';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.navisionExportProduits = function () {
    var url = 'navisionErp/exportProduits';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.exportMouvementsProduit = function () {
    var url = 'navisionErp/exportMouvementsProduit';
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.exportDemandeChargement = function () {
    var url = 'erp/sage/SageDemandeChargement';
    $window.location.href = AppSettings.apiUrl + url;
  };
  vm.navisionExportReglements = function () {
    var url = 'navisionErp/navisionExportReglements';
    $window.location.href = AppSettings.apiUrl + url;
  };




  vm.getTables = function () {
    WS.get('erp/getTables').then(
      function (response) {
        vm.tables = response.data;
        $scope.$apply();
      },
      function (error) {
        console.log('error : ->', error);
      }
    );
  };

  vm.getColumns = function () {
    WS.getData('erp/getColumns', { view_name: vm.selectedtables[0].view_name }).then(
      function (response) {
        vm.columns = response.data;
        $scope.$apply();
      },
      function (error) {
        console.log('error : ->', error);
      }
    );
  };


  vm.appliqFunction = (func) => {
    console.log(func)
    if (func) {

    }
  }







  vm.NSageImportClients = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('nomadisIntegration/importClients', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };


  vm.NSageExportClients = function () {
    var url = 'nomadisIntegration/exportClients';
    $window.location.href = AppSettings.apiUrl + url;
  };




  vm.NSageImportProduits = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('nomadisIntegration/importProduits', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };


  vm.NSageExportProduits = function () {
    var url = 'nomadisIntegration/exportProduits';
    $window.location.href = AppSettings.apiUrl + url;
  };


  /**
   * import leste des familles
   * @param file excel
   */

  vm.NSageImportFamilles = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('nomadisIntegration/importFamilles', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };


  vm.NSageExportFamilles = function () {
    var url = 'nomadisIntegration/exportFamilles';
    $window.location.href = AppSettings.apiUrl + url;
  };

  /**
   * import entetecommercials
   * @param file excel
   */

  vm.NSageImportEntetes = function () {
    vm.csvfile.filetxt = vm.files[0];
    WS.postwithimg('nomadisIntegration/importEntetes', vm.csvfile, "POST")
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            response.data.message,
            'Succès'
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            'Warning',
            response.data.message,
            'warning'
          );
        }
      }, function (error) {
        console.log(error);
      });
  };

  vm.winsys = {};
  vm.WINSYSCLIENTS = function () {
    // var url = 'nomadisIntegration/WINSYSCLIENTS';
    // $window.location.href = AppSettings.apiUrl + url;

    WS.getData('nomadisIntegration/WINSYSCLIENTS', vm.winsys).then(
      function (response) {

        //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
        var filename = 'Clients-'+ moment().format("YYYY-MM-DD HH:mm:ss") +'__' + vm.winsys.date_debut + '_'+vm.winsys.date_fin;

        var file = {
          sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          // caption: {
          //   title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          //   style: 'font-size: 50px; color:blue;'
          // },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [
            { columnid: "c1", title: 'Groupe de comptes client' },
            { columnid: "c2", title: 'Code Client' },
            { columnid: "c3", title: 'Société' },
            { columnid: "c4", title: 'Organis. Commerciale' },
            { columnid: "c5", title: 'Canal distribution' },
            { columnid: "c6", title: "Secteur d'activité" },
            { columnid: "c7", title: "Nom 1/nom de famille" },
            { columnid: "c8", title: "Nom2" },
            { columnid: "c9", title: "nom3" },
            { columnid: "c10", title: "Critère de recherche A" },
            { columnid: "c11", title: "ville" },
            { columnid: "c12", title: "Rue" },
            { columnid: "c13", title: "Pays" },
            { columnid: "c14", title: "Région" },
            { columnid: "c15", title: "Zone de transport" },
            { columnid: "c16", title: "Téléphone 1" },
            { columnid: "c17", title: "N° ident.TVA (Matricule fiscal)" },
            { columnid: "c18", title: "N° SIREN" },
            { columnid: "c19", title: "Personne physique" },
            { columnid: "c20", title: "Compte collectif" },
            { columnid: "c21", title: "Groupe prévisions trésorerie" },
            { columnid: "c22", title: "Clé de tri" },
            { columnid: "c23", title: "Anc. N°de cpte" },
            { columnid: "c24", title: "Conditions de paiement" },
            { columnid: "c25", title: "Noter habitudes paiement" },
            { columnid: "c26", title: "Classe tolérances" },
            { columnid: "c27", title: "Données relance" },
            { columnid: "c28", title: "Gestionnaire comptable" },
            { columnid: "c29", title: "Zone de distribution" },
            { columnid: "c30", title: "Groupe de statistiques" },
            { columnid: "c31", title: "Groupe clients" },
            { columnid: "c32", title: "Groupe de clients 1" },
            { columnid: "c33", title: "Groupe de clients 2" },
            { columnid: "c34", title: "Schéma clients" },
            { columnid: "c35", title: "Groupe de prix" },
            { columnid: "c36", title: "Devise" },
            { columnid: "c37", title: "Condition d'expédition" },
            { columnid: "c38", title: "Priorité de livraison" },
            { columnid: "c39", title: "Groupe d'imputation" },
            { columnid: "c40", title: "Incoterms1" },
            { columnid: "c41", title: "Incoterms2" },
            { columnid: "c42", title: "Condition paiement_Vente" },
            { columnid: "c43", title: "MWST" },
            { columnid: "c44", title: "ZMWS" },
            { columnid: "c45", title: "ZTAV" },
            { columnid: "c46", title: "ZTTI" },
          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };

        // alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);

        // Map response data to use column titles
        const dataWithTitles = response.data.map((row) => {
          const mappedRow = {};
          file.columns.forEach((col) => {
            mappedRow[col.title] = row[col.columnid] || ""; // Map values, default to empty string if not found
          });
          return mappedRow;
        });

        // // Export to CSV with correct headers
        alasql('SELECT * INTO CSV("' + filename + '.csv", {headers:true}) FROM ?', [dataWithTitles]);

      },
      function (error) {
        console.log(error);
      }
    );
  };

  // vm.WINSYSLIGNES = function(){
  //     var url='nomadisIntegration/WINSYSLIGNES';
  //     $window.location.href = AppSettings.apiUrl + url;
  // };

  //


  vm.WINSYSLIGNES = function () {
    WS.getData('nomadisIntegration/WINSYSLIGNES', vm.winsys).then(
      function (response) {

        //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
        var filename = 'Vente-'+ moment().format("YYYY-MM-DD HH:mm:ss") +'__' + vm.winsys.date_debut + '_'+vm.winsys.date_fin;

        var file = {
          sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          // caption: {
          //   title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          //   style: 'font-size: 50px; color:blue;'
          // },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [
            { columnid: "c1", title: "Commande_N°" },
            { columnid: "c2", title: "Type de document de vente" },
            { columnid: "c3", title: "Organisation commerciale" },
            { columnid: "c4", title: "Canal de distribution" },
            { columnid: "c5", title: "Secteur d'activité" },
            { columnid: "c6", title: "Sales Office" },
            { columnid: "c7", title: "Motif de la commande" },
            { columnid: "c8", title: "Blocage livr." },
            { columnid: "c9", title: "Blocage facture" },
            { columnid: "c10", title: "Client" },
            { columnid: "c11", title: "Client1" },
            { columnid: "c12", title: "Client2" },
            { columnid: "c13", title: "Client3" },
            { columnid: "c14", title: "Cond. Paiement" },
            { columnid: "c15", title: "Nº commande d'achat" },
            { columnid: "c16", title: "Date cde achat" },
            { columnid: "c17", title: "DATE" },
            { columnid: "c18", title: "incoterms" },
            { columnid: "c19", title: "Incoterms 2" },
            { columnid: "c20", title: "Type d'expédition" },
            { columnid: "c21", title: "Code produit" },
            { columnid: "c22", title: "Numéro d'article européen (EAN)" },
            { columnid: "c23", title: "Numéro d'article du client" },
            { columnid: "c24", title: "Quantite" },
            { columnid: "c25", title: "Unité de vente" },
            { columnid: "c26", title: "Division" },
            { columnid: "c27", title: "Motif de refus" },
            { columnid: "c28", title: "DATE1" },
            { columnid: "c29", title: "Qte" },
            { columnid: "c30", title: "PRIO" },
            { columnid: "c31", title: "Code depot (Champ NOMADIS)" }
          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };

        // alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);

        // Map response data to use column titles
        const dataWithTitles = response.data.map((row) => {
          const mappedRow = {};
          file.columns.forEach((col) => {
            mappedRow[col.title] = row[col.columnid] || ""; // Map values, default to empty string if not found
          });
          return mappedRow;
        });

        // // Export to CSV with correct headers
        alasql('SELECT * INTO CSV("' + filename + '.csv", {headers:true}) FROM ?', [dataWithTitles]);

      },
      function (error) {
        console.log(error);
      }
    );
  };



  vm.NSageExportEntetes = function () {
    var url = 'nomadisIntegration/exportEntetes';
    $window.location.href = AppSettings.apiUrl + url;
  };


  /**
   * export table get columns
   */
  vm.getColumns = (table_name) => {
    var url = 'nomadisIntegration/getColumns/' + table_name;
    $window.location.href = AppSettings.apiUrl + url;
  }


  /**
   * integration phenix
   */
  vm.PhenixUpdateClients = () => {
    WS.getData('nomadisIntegration/PhenixUpdateClients')
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            'Action effectué avec succés',
            'Succès'
          ).then(function () {
            //$window.location.reload();
          });
        }
      }, function (error) {
        console.log(error);
      });
  };


  vm.PhenixUpdateProduits = () => {
    WS.getData('nomadisIntegration/PhenixUpdateProduits')
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            'Action effectué avec succés',
            'Succès'
          ).then(function () {
            //$window.location.reload();
          });
        }
      }, function (error) {
        console.log(error);
      });
  };

  vm.PhenixUpdateEntetes = () => {
    WS.getData('nomadisIntegration/PhenixUpdateEntetes')
      .then(function (response) {
        if (response.data.success == true) {
          swal(
            'Succès',
            'Action effectué avec succés',
            'Succès'
          ).then(function () {
            //$window.location.reload();
          });
        }
      }, function (error) {
        console.log(error);
      });
  };

}
export default {
  name: 'integrationerpController',
  fn: integrationerpController
};
