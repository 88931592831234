function blController($http, $state, $scope, WS, $compile, $parse, Flash, $window, AppSettings, $translate, $uibModal) {
    'ngInject';
    const vm = this;

    vm.currentLang = $translate.proposedLanguage() || $translate.use();
    vm.decimal = AppSettings.decimal;

    vm.tabsFacture = {};
    vm.tabsFacture.bls = [];
    vm.tabsFacture.remiseEspece = 0;
    vm.tabsFacture.ttc = 0;
    vm.tabsFacture.net = 0;
    vm.creditByClient=0;
    vm.bls = {};
    vm.center = "35.981789, 10.076292";
    vm.countblock = 1;
    vm.newbl = {};
    vm.newbl.totalRemise = 0;
    vm.newbl.totalTtc = 0;
    vm.newbl.totalNet = 0;
    vm.newbl.gratuites = [];
    vm.newbl.lignes = [];
    vm.class_md_prod = 'col-md-3';
    vm.keymaps = AppSettings.keymaps;
    vm.per_page = "10";
    vm.quantites = {};

    vm.listofProdGratuite = '';

    /**
     * CHECK CONTROLE DE STOCK
     */
    WS.getScache('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                cache: false
            }).then(function successCallback(response) {
                vm.global_gestion_stock = response.data.gestion_stock;
                //vm.global_gestion_stock = 1;
                if (vm.global_gestion_stock == 1) {
                    vm.class_md_prod = 'col-md-2';
                } else {
                    vm.class_md_prod = 'col-md-4';
                }
                //console.log('vm.global_gestion_stock', vm.global_gestion_stock);
            }, function errorCallback(error) { });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });


    vm.searchByDate = function (bldate, type = 'bl', cadeaux_valide = null, cadeaux_annule = null) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            if (bldate.commercial && bldate.commercial != "") {
                var url = 'bl/getByDate/' + bldate.date_debut + '/' + bldate.date_fin + '/' + type + '/' + bldate.commercial;
            } else {
                var url = 'bl/getByDate/' + bldate.date_debut + '/' + bldate.date_fin + '/' + type;
            }
            WS.getData(url, { cadeaux_valide: cadeaux_valide,cadeaux_annule: bldate.cadeaux_annule })
                .then(function (response) {
                    vm.bls = {};
                    vm.totalremise = 0;
                    vm.totalnet = 0;
                    vm.bls = response.data;
                    response.data.forEach(function (e, index) {
                        vm.totalremise += parseFloat(Math.abs(e.remise));
                        vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                    });
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    }

    vm.searchByDateDeleted = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            if (bldate.commercial && bldate.commercial != "") {
                var url = 'entetecommercial/deleted_entetes/' + bldate.date_debut + '/' + bldate.date_fin + '/' + bldate.commercial;
            } else {
                var url = 'entetecommercial/deleted_entetes/' + bldate.date_debut + '/' + bldate.date_fin;
            }
            WS.get(url)
                .then(function (response) {
                    vm.deleted_entetes = {};
                    vm.deleted_entetes = response.data;
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    }
    vm.exportExcel = function (bldate, withavoir = null, custom = false) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            if (withavoir) {
                //withavoir = 'avoir';
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/bl/0/avoir';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/' + bldate.commercial;

                }
            } else {
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/bl';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/null/null/' + bldate.commercial;
                }
            }

            WS.get(url)
                .then(function (response) {
                    var date_debut = response.data[response.data.length - 1].date_creation;
                    var date_fin = response.data[0].date_creation;
                    var filename = 'Bl-' + date_debut + '-_-_-' + date_fin;
                    if (withavoir) {
                        filename = 'Bl+avoir-' + date_debut + '-_-_-' + date_fin;
                    }


                    var all_data = [
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'code_erp_produit', title: 'code_erp produit' },
                        { columnid: 'code_a_barre', title: 'code_a_barre produit' },
                        { columnid: 'produit', title: 'Produit' },
                        { columnid: 'marque', title: 'Marque' },
                        { columnid: 'gamme', title: 'Gamme' },
                        { columnid: 'famille', title: 'Famille' },
                        { columnid: 'sousfamille', title: 'Sous_Famille' },
                        { columnid: 'fournisseur', title: 'Fournisseur' },
                        { columnid: 'soussociete', title: 'Sous societe' },
                        { columnid: 'code_elastic', title: 'Site' },
                        { columnid: 'entetecommercial_code', title: 'BL_N°' },
                        { columnid: 'quantite', title: 'Quantite' },
                        { columnid: 'carton', title: 'Carton' },
                        { columnid: 'colisage', title: 'Colisage' },
                        { columnid: 'num_lot', title: 'N.Lot' },
                        { columnid: 'pu_ht', title: 'PU_HT' },
                        { columnid: 'pu_ttc', title: 'PU_TTC' },
                        { columnid: 'prix_achat_ttc', title: 'prix_achat' },
                        { columnid: 'p_tva', title: 'p_tva' },
                        { columnid: 'taux_tva', title: 'TVA' },
                        { columnid: 'total', title: 'Total' },
                        { columnid: 'total_net', title: 'Total Net' },
                        { columnid: 'total_ht', title: 'Total HT' },
                        { columnid: 'total_ht_net', title: 'Total HT Net' },
                        { columnid: 'date_creation', title: 'DATE' },
                        { columnid: 'taux_remise', title: 'Taux_remise' },
                        { columnid: 'remise', title: 'Remise' },
                        { columnid: 'remise_commande', title: 'Remise escompte' },
                        { columnid: 'remise_pied', title: 'Remise pied' },
                        { columnid: 'type', title: 'Type' },
                        { columnid: 'type_ligne', title: 'Type ligne' },
                        { columnid: 'code_commercial', title: 'Code Commercial' },
                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'code_client', title: 'code_client' },
                        { columnid: 'code_erp_client', title: 'code_erp_client' },
                        { columnid: 'cab_client', title: 'code_a_a_barre_client' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'matricule_fiscale', title: 'Matricule fiscale' },
                        { columnid: 'rc', title: 'RC' },

                        { columnid: 'presentoire', title: 'presentoire' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'routing_code', title: 'Route' },
                        { columnid: 'region', title: 'Region' },
                        { columnid: 'gouvernorat', title: 'Gouvernorat' },
                        { columnid: 'zone', title: 'Zone' },
                        { columnid: 'categorie', title: 'Categorie' },
                        { columnid: 'activite', title: 'Activite' },
                        { columnid: 'classement', title: 'Classement' },
                        { columnid: 'longitude', title: 'longitude piece' },
                        { columnid: 'latitude', title: 'latitude piece' },
                        { columnid: 'longitudeClient', title: 'longitude Client' },
                        { columnid: 'latitudeClient', title: 'latitude Client' }
                    ];

                    var custom_data = [
                        { columnid: 'entetecommercial_code', title: 'BL_N°' },
                        { columnid: 'code_client', title: 'code_client' },
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'type', title: 'Type' },
                        { columnid: 'type_ligne', title: 'Type ligne' },
                        { columnid: 'total_net', title: 'Total Net' },
                        { columnid: 'total_ht', title: 'Total HT' },
                        { columnid: 'total_ht_net', title: 'Total HT Net' },
                        { columnid: 'remise', title: 'Remise' },
                        { columnid: 'quantite', title: 'Quantite' },
                        { columnid: 'carton', title: 'Carton' },
                        { columnid: 'colisage', title: 'Colisage' },
                        { columnid: 'num_lot', title: 'N.Lot' },
                        { columnid: 'pu_ht', title: 'PU_HT' },
                        { columnid: 'pu_ttc', title: 'PU_TTC' },
                        { columnid: 'p_tva', title: 'p_tva' },
                        { columnid: 'taux_tva', title: 'TVA' },
                        { columnid: 'total', title: 'Total' },
                        { columnid: 'date_creation', title: 'DATE' },
                        { columnid: 'taux_remise', title: 'Taux_remise' },
                        { columnid: 'remise_pied', title: 'Remise pied' },
                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'code_commercial', title: 'Code Commercial' }
                    ];
                    var stylecsv = {
                        sheetid: filename,
                        headers: true,
                        caption: {
                            title: filename,
                            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                        },
                        //style:'background:#fff',
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: custom ? custom_data : all_data,
                        row: {
                            style: function (sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };


    vm.exportCadeaux = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/cadeaux/'+bldate.cadeaux_annule;
            if (bldate.commercial && bldate.commercial != "") {
                var url = url + '/null/' + bldate.commercial;
            }

            WS.get(url)
                .then(function (response) {
                    var date_debut = response.data[response.data.length - 1].date_creation;
                    var date_fin = response.data[0].date_creation;
                    var filename = 'Cadeaux-' + date_debut + '-_-_-' + date_fin;

                    var stylecsv = {
                        sheetid: filename,
                        headers: true,
                        caption: {
                            title: filename,
                            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                        },
                        //style:'background:#fff',
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: [
                            { columnid: 'code', title: 'Code' },
                            { columnid: 'code_erp_produit', title: 'code_erp produit' },
                            { columnid: 'code_a_barre', title: 'code_a_barre produit' },
                            { columnid: 'produit', title: 'Produit' },
                            { columnid: 'marque', title: 'Marque' },
                            { columnid: 'gamme', title: 'Gamme' },
                            { columnid: 'famille', title: 'Famille' },
                            { columnid: 'sousfamille', title: 'Sous_Famille' },
                            { columnid: 'soussociete', title: 'Sous societe' },
                            { columnid: 'code_elastic', title: 'Site' },
                            { columnid: 'entetecommercial_code', title: 'BL_N°' },
                            { columnid: 'quantite', title: 'Quantite' },
                            { columnid: 'quantite_achete', title: 'Quantite Achete' },
                            { columnid: 'quantite_gratuite', title: 'Quantite Gratuite' },
                            { columnid: 'commentaire', title: 'Commentaire' },
                            { columnid: 'num_lot', title: 'N.Lot' },
                            { columnid: 'pu_ht', title: 'PU_HT' },
                            { columnid: 'pu_ttc', title: 'PU_TTC' },
                            { columnid: 'prix_achat_ttc', title: 'prix_achat' },
                            { columnid: 'p_tva', title: 'p_tva' },
                            { columnid: 'taux_tva', title: 'TVA' },
                            { columnid: 'total', title: 'Total' },
                            { columnid: 'total_net', title: 'Total Net' },
                            { columnid: 'total_ht', title: 'Total HT' },
                            { columnid: 'total_ht_net', title: 'Total HT Net' },
                            { columnid: 'date_creation', title: 'DATE' },
                            { columnid: 'taux_remise', title: 'Taux_remise' },
                            { columnid: 'remise', title: 'Remise' },
                            { columnid: 'remise_commande', title: 'Remise escompte' },
                            { columnid: 'remise_pied', title: 'Remise pied' },
                            { columnid: 'type', title: 'Type' },
                            { columnid: 'type_cadeaux', title: 'Type Cadeaux' },
                            { columnid: 'code_commercial', title: 'Code Commercial' },
                            { columnid: 'user_validate_cadeaux', title: 'User validate cadeaux' },
                            { columnid: 'commercial', title: 'Commercial' },
                            { columnid: 'code_client', title: 'code_client' },
                            { columnid: 'code_erp_client', title: 'code_erp_client' },
                            { columnid: 'cab_client', title: 'code_a_a_barre_client' },
                            { columnid: 'adresse_facturation', title: 'Adresse' },
                            { columnid: 'matricule_fiscale', title: 'Matricule fiscale' },
                            { columnid: 'rc', title: 'RC' },
                            { columnid: 'presentoire', title: 'presentoire' },
                            { columnid: 'client', title: 'Client' },
                            { columnid: 'classement', title: 'Classement' },
                            { columnid: 'magasin', title: 'Magasin' },
                            { columnid: 'routing_code', title: 'Route' },
                            { columnid: 'region', title: 'Region' },
                            { columnid: 'gouvernorat', title: 'Gouvernorat' },
                            { columnid: 'zone', title: 'Zone' }
                        ],
                        row: {
                            style: function (sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };

    vm.exportbl = function (code) {

        var url = 'bl/getExportBl/' + code;

        WS.get(url)
            .then(function (response) {
                var date_debut = response.data[response.data.length - 1].date_creation;
                var date_fin = response.data[0].date_creation;
                var filename = 'Bl-' + code;
                var stylecsv = {
                    sheetid: filename,
                    headers: true,
                    caption: {
                        title: filename,
                        style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                    },
                    //style:'background:#fff',
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'code_erp_produit', title: 'code_erp produit' },
                        { columnid: 'produit', title: 'Produit' },
                        { columnid: 'marque', title: 'Marque' },
                        { columnid: 'gamme', title: 'Gamme' },
                        { columnid: 'famille', title: 'Famille' },
                        { columnid: 'sousfamille', title: 'Sous_Famille' },
                        { columnid: 'soussociete', title: 'Sous societe' },
                        { columnid: 'entetecommercial_code', title: 'BL_N°' },
                        { columnid: 'remise_commande', title: 'Remise escompte' },
                        { columnid: 'remise_pied', title: 'Remise pied' },
                        { columnid: 'quantite', title: 'Quantite' },
                        { columnid: 'num_lot', title: 'N.Lot' },
                        { columnid: 'pu_ht', title: 'PU_HT' },
                        { columnid: 'pu_ttc', title: 'PU_TTC' },
                        { columnid: 'prix_achat_ttc', title: 'prix_achat' },
                        { columnid: 'p_tva', title: 'p_tva' },
                        { columnid: 'taux_tva', title: 'TVA' },
                        { columnid: 'total', title: 'Total' },
                        { columnid: 'total_net', title: 'Total Net' },
                        { columnid: 'total_ht', title: 'Total HT' },
                        { columnid: 'date_creation', title: 'DATE' },
                        { columnid: 'taux_remise', title: 'Taux_remise' },
                        { columnid: 'remise', title: 'Remise' },
                        { columnid: 'type', title: 'Type' },
                        { columnid: 'code_commercial', title: 'Code Commercial' },
                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'code_client', title: 'code_client' },
                        { columnid: 'code_erp_client', title: 'code_erp_client' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'matricule_fiscale', title: 'Matricule fiscale' },
                        { columnid: 'rc', title: 'RC' },
                        { columnid: 'presentoire', title: 'presentoire' },
                        { columnid: 'routing_code', title: 'Route' },
                        { columnid: 'localite', title: 'Localite' },
                        { columnid: 'region', title: 'Region' },
                        { columnid: 'gouvernorat', title: 'Gouvernorat' },
                        { columnid: 'secteur', title: 'Secteur' },
                        { columnid: 'zone', title: 'Zone' },
                        { columnid: 'classement', title: 'Classement' },
                        { columnid: 'longitude', title: 'longitude piece' },
                        { columnid: 'latitude', title: 'latitude piece' },
                        { columnid: 'longitudeClient', title: 'longitude Client' },
                        { columnid: 'latitudeClient', title: 'latitude Client' }
                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.waveSoft = function (bldate, withavoir = null) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            if (withavoir) {
                //withavoir = 'avoir';
                var url = 'bl/getByDateExport/wavesoft/' + bldate.date_debut + '/' + bldate.date_fin + '/bl/avoir';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/' + bldate.commercial;
                }
            } else {
                var url = 'bl/getByDateExport/wavesoft/' + bldate.date_debut + '/' + bldate.date_fin + '/bl';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/null/' + bldate.commercial;
                }
            }
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    vm.wavesoft_reglement = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            var url = 'bl/getByDateExport/wavesoft_reglement/' + bldate.date_debut + '/' + bldate.date_fin;
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    vm.wavesoft_mouvement = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            var url = 'bl/getByDateExport/wavesoft_mouvement/' + bldate.date_debut + '/' + bldate.date_fin;
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    vm.exportExcelEntete_bl = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            var url = 'entetecommercial/exportExcel/entete_bl/' + bldate.date_debut + '/' + bldate.date_fin;
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    vm.exportExcelEntete_bl_details = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            var url = 'entetecommercial/exportExcel/entete_bl_details/' + bldate.date_debut + '/' + bldate.date_fin;
            $window.location.href = AppSettings.apiUrl + url;
        }
    };
    vm.exportExcelDetailReg = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            var url = 'entetecommercial/exportExcel/exportExcelDetailReg/' + bldate.date_debut + '/' + bldate.date_fin;
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    vm.exportExcelChargements = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            var url = 'entetecommercial/exportExcel/exportExcelChargement/' + bldate.date_debut + '/' + bldate.date_fin;
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    vm.selectUsers = function () {
        WS.get('users/selectUsers')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.selectAdmins = function () {
        WS.get('users/admin_dashboard')
            .then(function (response) {
                if (response.status == 200) {
                    vm.admin_dashboard = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getBlscleared = function (code) {
        WS.get('entetecommercial/top50/bl', vm.access)
            .then(function (response) {
                vm.totalremise = 0;
                vm.totalnet = 0;
                vm.bls = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalremise += parseFloat(Math.abs(e.remise));
                    vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                });

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getCadeauxcleared = function (code) {
        WS.getData('entetecommercial/top50/cadeaux', vm.access)
            .then(function (response) {
                vm.bls = response.data;
                //dataGrid_DevExpress();
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getBls = function () {

        var user_code = $state.params.user_code ? $state.params.user_code : '';
        var date_debut = $state.params.date_debut ? $state.params.date_debut : '';
        var date_fin = $state.params.date_fin ? $state.params.date_fin : '';
        vm.bldate = {};
        vm.bldate.commercial = user_code;
        vm.bldate.date_debut = date_debut;
        vm.bldate.date_fin = date_fin;

        if(!user_code && !date_debut && !date_fin){
            var url = 'entetecommercial/top50/bl';
        }
        if(user_code && !date_debut && !date_fin){
            var url = 'entetecommercial/top50/bl/' + user_code;
        }
        if(user_code && date_debut && date_fin){
            var url = 'entetecommercial/top50/bl/' + user_code + '/' + date_debut + '/' + date_fin;
        }
        if(!user_code && date_debut && date_fin){
            var url = 'entetecommercial/top50/bl/' + null + '/' + date_debut + '/' + date_fin;
        }


        WS.getScache(url)
            .then(function (response) {
                vm.totalremise = 0;
                vm.totalnet = 0;
                vm.bls = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalremise += parseFloat(Math.abs(e.remise));
                    vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                });
                $scope.$apply();
                /*$('.table-bls').DataTable({
                    "language": DatatableLang,
                    "searching": false,
                    "paging": false,
                    "lengthChange": false,
                    "bInfo": false,
                    order: [
                        [9, 'DESC']
                    ]

                });*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getDeletedEntete = function () {

        var url = 'entetecommercial/deleted_entetes';


        WS.getScache(url)
            .then(function (response) {
                vm.deleted_entetes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getCadeaux = function () {

        var user_code = $state.params.user_code;
        var date_debut = $state.params.date_debut;
        var date_fin = $state.params.date_fin;

        if (user_code != '' && user_code != undefined) {
            vm.bldate = {};
            vm.bldate.commercial = user_code;
            vm.bldate.date_debut = date_debut;
            vm.bldate.date_fin = date_fin;
            var url = 'entetecommercial/top50/cadeaux/' + user_code + '/' + date_debut + '/' + date_fin;
        } else {
            var url = 'entetecommercial/top50/cadeaux';
        }

        WS.getScache(url)
            .then(function (response) {
                vm.totalremise = 0;
                vm.totalnet = 0;
                vm.bls = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalremise += parseFloat(Math.abs(e.remise));
                    vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                });
                $scope.$apply();
                /*$('.table-bls').DataTable({
                    "language": DatatableLang,
                    "searching": false,
                    "paging": false,
                    "lengthChange": false,
                    "bInfo": false,
                    order: [
                        [9, 'DESC']
                    ]

                });*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getCadeauxNonValide = function () {


        var url = 'entetecommercial/top50/cadeaux';


        WS.getData(url, { cadeaux_valide: 'nonvalide' })
            .then(function (response) {
                vm.totalremise = 0;
                vm.totalnet = 0;
                vm.bls = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalremise += parseFloat(Math.abs(e.remise));
                    vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                });
                $scope.$apply();
                /*$('.table-bls').DataTable({
                    "language": DatatableLang,
                    "searching": false,
                    "paging": false,
                    "lengthChange": false,
                    "bInfo": false,
                    order: [
                        [9, 'DESC']
                    ]

                });*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    }



    vm.validerCadeaux = (code) => {


        swal({
            title: $translate.instant(`Validation`),
            // text: $translate.instant('code bl') + ` : `,
            html: `${$translate.instant('Voulez-vous vraiment accepter ce cadeau!')}
                    <h4 class="text-center">${code}</h4>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.post('entetecommercial/validerCadeaux',
                {
                    code: code,
                    type_cadeau: vm.type_cadeau ,
                    quantite_achete: vm.quantite_achete,
                    quantite_gratuite: vm.quantite_gratuite,
                    commentaire: vm.commentaire
                })
            .then(
                (response) => {
                    if (response.data.success) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Cadeau validé'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });;
                    }
                    $scope.$apply();
                },
                (error) => {
                    console.log(error);
                }
            )
        });


    }

    vm.selectCommercialCommande = () => {
        WS.getData('entetecommercial/selectCommercialCommande', { code: vm.details.code_commande }).then(
            (response) => {
                vm.commande_user = response.data;
                console.log('commande_user', vm.commande_user);
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        )
    };
    vm.getDetailsbyCode = function (code) {
        var url = 'entetecommercial/code/' + $state.params.id;
        WS.get(url)
            .then(function (response) {
                vm.details = response.data;
                vm.signatureclient = response.data.client;
                vm.signatureclientBl = null;
                vm.imageproduitbl = null;
                vm.count_commentaire = 0;



                _.each(vm.details.image, (element, key) => {

                    if (element.code == $state.params.id && element.type == 'signature') {
                        vm.signatureclientBl = element.path;
                     };
                    if (element.code == $state.params.id && element.type == 'bl') {
                        vm.imageproduitbl = element.path;
                     };
                });
                _.each(vm.details.paiements, (element, key) => {
                    if (element.commentaire != "") {
                        vm.count_commentaire++;
                    };
                });
                if (vm.details.code_commande != null) {
                    vm.selectCommercialCommande();
                } else {
                    vm.commande_user = {};
                    vm.commande_user.user = null;
                }
                // var test = _writtenNumber(76.500, {lang: 'ar', baseSeparator : '.'});
                //console.log('test', test);
                $scope.$apply();

                //console.log('details', vm.details);
                $('#mapsDetailsBl').empty();
                $('#mapsDetailsBl').append('<div style="width: 100%; height: 580px" id="mapContainerDetailsBl"></div>');

                var mymap = L.map('mapContainerDetailsBl').setView([vm.details.latitude, vm.details.longitude], 17);

                //  L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
                //      attribution: 'Map data &copy; <a href="https://www.clediss.com/">Nomadis</a> ©',
                //      maxZoom: 18,
                //      id: 'mapbox.streets',
                //      accessToken: AppSettings.leaflet.access_token
                //  }).addTo(mymap);

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                })
                    .addTo(mymap);

                //  L.tileLayer(`http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?
                //      app_id=${AppSettings.herewego.app_id}&app_code=${AppSettings.herewego.app_code}`
                //      , {
                //      styleId: 997
                //    }).addTo(mymap);

                // Creates a red marker with the coffee icon
                var redMarkerClient = L.ExtraMarkers.icon({
                    icon: 'fa-home',
                    markerColor: 'blue',
                    shape: 'circle', //'circle', 'square', 'star', or 'penta'
                    prefix: 'fa'
                });
                var markerClient = L.marker([vm.details.client.latitude, vm.details.client.longitude], { icon: redMarkerClient }).addTo(mymap);
                var infoClient = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                <a ui-sref="app.clients.historiques({code : '${vm.details.client_code}'})" target="_blank">
                                                                  Historique
                                                                </a>
                                                              </div>
                                                              <div><b>BL N°</b> : ${vm.details.code}</a></div>
                                                              <div><b>Client</b> : ${vm.details.client.nom} ${vm.details.client.prenom}</a></div>
                                                              <div><b>Livreur</b> : ${vm.details.commercial.nom} ${vm.details.commercial.prenom}</a></div>
                                                              <div><b>Montant</b> : ${vm.details.net_a_payer}</div>
                                                              <div><b>latitude</b> : ${vm.details.client.latitude}</div>
                                                              <div><b>longitude</b> : ${vm.details.client.longitude}</div>
                                                            </div>
                                                        `)($scope);
                var popupClient = L.popup({
                    maxWidth: 600,
                    minWidth: 300
                }).setContent(infoClient[0])
                markerClient.bindPopup(popupClient);

                var redMarkerVisite = L.ExtraMarkers.icon({
                    icon: 'fa-eye',
                    markerColor: 'red',
                    shape: 'circle', //'circle', 'square', 'star', or 'penta'
                    prefix: 'fa'
                });
                var markerVisite = L.marker([vm.details.latitude, vm.details.longitude], { icon: redMarkerVisite }).addTo(mymap);
                var infoVisite = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                ${$translate.instant('Visite')}
                                                              </div>
                                                              <div><b>BL N°</b> : ${vm.details.code}</a></div>
                                                              <div><b>Livreur</b> : ${vm.details.commercial.nom} ${vm.details.commercial.prenom}</a></div>
                                                              <div><b>Montant</b> : ${vm.details.net_a_payer}</div>
                                                              <div><b>latitude</b> : ${vm.details.latitude}</div>
                                                              <div><b>longitude</b> : ${vm.details.longitude}</div>
                                                            </div>
                                                        `)($scope);
                var popupVisite = L.popup({
                    maxWidth: 600,
                    minWidth: 300
                }).setContent(infoVisite[0])
                markerVisite.bindPopup(popupVisite);

                setTimeout(function () {
                    mymap.invalidateSize();
                }, 80);
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.getencourParClient = function () {
        WS.get('mouvement_clients/getCreditByClients/'+$state.params.id).then(
            function (response) {
                vm.creditByClient = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };


    vm.selectDepots = function () {
        WS.get('depots/selectDepot').then(
            function (response) {
                vm.depots = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };



    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                if (vm.soussocietes.length == 1) {
                    vm.newbl.distributeur_code = vm.soussocietes[0].code;
                }
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };


    vm.getCreditByClients = function (code) {
        WS.get('mouvement_clients/getCreditByClients'+ code).then(
            function (response) {
                vm.creditByClient = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectClientsWithCode = function () {
        WS.get('clients/selectClientsWithCode').then(
            function (response) {
                vm.clients = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectProduitsWithCode = function () {
        WS.get('produits/selectProduitsWithCode').then(
            function (response) {
                vm.produits = response.data;
                vm.stocked_lists_products = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };



    vm.storeCadeauxWeb = function () {

        if (vm.newbl.distributeur_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un distributeur"));
            return;
        }
        if (vm.newbl.depot_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un depot"));
            return;
        }
        if (vm.newbl.client_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un client"));
            return;
        }


        angular.forEach(vm.newbl.lignes, function (value, key) {
            if (value.produit_code == undefined) {
                Flash.create('danger', $translate.instant("Veuillez bien remplir la liste des produits"));
                return;
            }
            if (value.quantite == undefined) {
                Flash.create('danger', $translate.instant("Veuillez bien remplir la quantite dans la liste des produits"));
                return;
            }
        });


        if (vm.global_gestion_stock == 1) {
            var authorize_call = 0;
            _.each(vm.newbl.lignes, (element, key) => {
                if (element.stock < element.quantite || element.quantite <= 0) {
                    authorize_call++;
                }
            });
            if (authorize_call == 0) {
                WS.post('entetecommercial/storeCadeaux', vm.newbl).then(
                    function (response) {
                        vm.newbl = {};
                        vm.newbl.lignes = {};
                        vm.newbl.totalRemise = 0;
                        vm.newbl.totalTtc = 0;
                        vm.newbl.totalNet = 0;
                        Flash.create('success', $translate.instant("Bl ajoutée avec succée"));
                        vm.bls.unshift(response.data);
                        $('#block-0').nextAll().remove();
                        $state.go("app.cadeaux.details", { id: response.data.code });
                        $('.modal-backdrop').remove();
                        $scope.$apply();
                    },
                    function (error) {
                        console.log(error);
                    });
            } else {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('veuillez bien vérifier les quantités supérieures à stock ou les quantités négatives'),
                    'warning'
                )
            }
        } else {
            WS.post('entetecommercial/storeCadeaux', vm.newbl).then(
                function (response) {
                    vm.newbl = {};
                    vm.newbl.lignes = {};
                    vm.newbl.totalRemise = 0;
                    vm.newbl.totalTtc = 0;
                    vm.newbl.totalNet = 0;
                    Flash.create('success', $translate.instant("Bl ajoutée avec succée"));
                    //vm.bls.unshift(response.data);
                    $('#block-0').nextAll().remove();
                    $state.go("app.cadeaux.details", { id: response.data.code });
                    $('.modal-backdrop').remove();
                    $scope.$apply();
                },
                function (error) {
                    console.log(error);
                });
        }
    };


    vm.storeBl = function () {

        if (vm.newbl.distributeur_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un distributeur"));
            return;
        }
        if (vm.newbl.depot_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un depot"));
            return;
        }
        if (vm.newbl.client_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un client"));
            return;
        }

        var credit = 0;
        credit += (vm.newbl.totalNetTtc) ? Number(vm.newbl.totalNetTtc) : 0;
        credit -= (vm.newbl.espece) ? Number(vm.newbl.espece) : 0;
        credit -= (vm.newbl.cheque) ? Number(vm.newbl.cheque) : 0;
        credit -= (vm.newbl.traite) ? Number(vm.newbl.traite) : 0;
        credit -= (vm.newbl.ticket_cadeau_resultat) ? Number(vm.newbl.ticket_cadeau_resultat) : 0;

        if (
            credit > 0 &&
            (angular.isUndefined(vm.newbl.date_echeance_credit) || vm.newbl.date_echeance_credit == '')
        ) {
            Flash.create('danger', $translate.instant("Veuillez bien saisir le date decheance credit!"));
            return;
        }
        angular.forEach(vm.newbl.lignes, function (value, key) {
            if (value.produit_code == undefined) {
                Flash.create('danger', $translate.instant("Veuillez bien remplir la liste des produits"));
                return;
            }
            if (value.quantite == undefined) {
                Flash.create('danger', $translate.instant("Veuillez bien remplir la quantite dans la liste des produits"));
                return;
            }
        });

        if ((!angular.isUndefined(vm.newbl.cheque) && vm.newbl.cheque != "") || (!angular.isUndefined(vm.newbl.num_cheque) && vm.newbl.num_cheque != "")) {
            if (typeof vm.newbl.banque_cheque == "undefined" || vm.newbl.banque_cheque == "" || typeof vm.newbl.date_echeance_cheque == "undefined" || vm.newbl.date_echeance_cheque == "") {
                Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du cheque"));
                return;
            }
        }
        if ((!angular.isUndefined(vm.newbl.banque_cheque) && vm.newbl.banque_cheque != "") || (!angular.isUndefined(vm.newbl.date_echeance_cheque) && vm.newbl.date_echeance_cheque != "")) {
            if (typeof vm.newbl.cheque == "undefined" || vm.newbl.cheque == "" || typeof vm.newbl.num_cheque == "undefined" || vm.newbl.num_cheque == "") {
                Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du cheque"));
                return;
            }
        }
        if ((!angular.isUndefined(vm.newbl.traite) && vm.newbl.traite != "") || (!angular.isUndefined(vm.newbl.num_traite) && vm.newbl.num_traite != "")) {
            if (typeof vm.newbl.banque_traite == "undefined" || vm.newbl.banque_traite == "" || typeof vm.newbl.date_echeance_traite == "undefined" || vm.newbl.date_echeance_traite == "") {
                Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du traite"));
                return;
            }
        }
        if ((!angular.isUndefined(vm.newbl.banque_traite) && vm.newbl.banque_traite != "") || (!angular.isUndefined(vm.newbl.date_echeance_traite) && vm.newbl.date_echeance_traite != "")) {
            if (typeof vm.newbl.traite == "undefined" || vm.newbl.traite == "" || typeof vm.newbl.num_traite == "undefined" || vm.newbl.num_traite == "") {
                Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du traite"));
                return;
            }
        }

        if ((vm.newbl.ticket_cadeau_quantite == "" || vm.newbl.ticket_cadeau_quantite == 0 || vm.newbl.ticket_cadeau_quantite == undefined) && vm.newbl.ticket_cadeau_type != undefined && vm.newbl.ticket_cadeau_type != "") {
            Flash.create('danger', $translate.instant("Veuillez bien choisir le type des tickets"));
            return;
        }

        if ((vm.newbl.ticket_cadeau_type != "" || vm.newbl.ticket_cadeau_type != undefined) &&
            vm.newbl.ticket_cadeau_quantite == 0 || vm.newbl.ticket_cadeau_quantite == undefined && vm.newbl.ticket_cadeau_quantite == "") {
            Flash.create('danger', $translate.instant("Veuillez bien choisir la quantite des tickets"));
            return;
        }
        console.log(vm.newbl);
        if (vm.global_gestion_stock == 1) {
            var authorize_call = 0;
            _.each(vm.newbl.lignes, (element, key) => {
                if (element.stock < element.quantite || element.quantite <= 0) {
                    authorize_call++;
                }
            });
            if (authorize_call == 0) {
                WS.post('entetecommercial/storeBl/bl', vm.newbl).then(
                    function (response) {
                        vm.newbl = {};
                        vm.newbl.lignes = {};
                        vm.newbl.totalRemise = 0;
                        vm.newbl.totalTtc = 0;
                        vm.newbl.totalNet = 0;
                        Flash.create('success', $translate.instant("Bl ajoutée avec succée"));
                        vm.bls.unshift(response.data);
                        $('#block-0').nextAll().remove();
                        $state.go("app.bl.details", { id: response.data.code });
                        $('.modal-backdrop').remove();
                        $scope.$apply();
                    },
                    function (error) {
                        console.log(error);
                    });
            } else {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('veuillez bien vérifier les quantités supérieures à stock ou les quantités négatives'),
                    'warning'
                )
            }
        } else {
            WS.post('entetecommercial/storeBl/bl', vm.newbl).then(
                function (response) {
                    vm.newbl = {};
                    vm.newbl.lignes = {};
                    vm.newbl.totalRemise = 0;
                    vm.newbl.totalTtc = 0;
                    vm.newbl.totalNet = 0;
                    Flash.create('success', $translate.instant("Bl ajoutée avec succée"));
                    //vm.bls.unshift(response.data);
                    $('#block-0').nextAll().remove();
                    $state.go("app.bl.details", { id: response.data.code });
                    $('.modal-backdrop').remove();
                    $scope.$apply();
                },
                function (error) {
                    console.log(error);
                });
        }
    };


    vm.transformerBl = function (cmd) {

        swal({
            title: 'Voulez-vous Transformer le BL',
            text: $translate.instant(`N° : ${cmd.code} en facture`),
            //text: $translate.instant(`Voulez-vous vraiment Transformer cette commande en bon de livraison`) + ' : ' + cmd.code,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {
            WS.post('bl/transformerBl', cmd).then(
                (response) => {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('Cloture'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }).catch(function (error) {
            //console.log(error);
        });
    };

    vm.changeDistributeur = () => {
        if (vm.newbl) {
            if (vm.newbl.lignes.length > 0) {
                _.each(vm.newbl.lignes, (element, index) => {
                    vm.getPuProduct(index, element.produit_code);
                })
            }
        }
    }

    vm.addBlock = function (index) {
        var newBlock = $compile(`<div id="block-` + index + `">
                            <div class="{{vm.class_md_prod}}">
                                <div class="form-group">
                                    <span class="c_red pull-right"
                                            ng-if="vm.use_quota==1 && vm.newbl.lignes[` + index + `].produit_code"
                                            title="{{'Quota' | translate }}" style="color:red!important;margin-top:-13px!important;">
                                    {{'Quota' | translate }}: {{vm.newbl.lignes[` + index + `].quota}}
                                    </span>
                                  <div ui-select name="produit-` + index + `" ng-model="vm.newbl.lignes[` + index + `].produit_code" theme="bootstrap"
                                      ng-change="vm.getPuProduct(` + index + `, vm.newbl.lignes[` + index + `].produit_code);
                                                vm.checkGratuiter(` + index + `, vm.newbl.lignes[` + index + `].produit_code, vm.newbl.lignes[` + index + `].quantite);" >
                                    <ui-select-match placeholder="{{'Produit' | translate}}...">{{$select.selected.libelle}}</ui-select-match>
                                    <ui-select-choices repeat="item.code as item in vm.produits | filter: $select.search">
                                      <div ng-bind-html="item.libelle | highlight: $select.search"></div>
                                    </ui-select-choices>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-1 text-center"  ng-if="vm.global_gestion_stock == 1">
                                <input type="text" disabled class="form-control" ng-model="vm.newbl.lignes[` + index + `].stock">
                            </div>
                            <div class="col-md-1 text-center" ng-if="vm.global_gestion_stock == 1">
                                <input type="text" disabled class="form-control" ng-model="vm.newbl.lignes[` + index + `].stock_carton">
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control"
                                            ng-model="vm.newbl.lignes[` + index + `].quantite" required
                                            ng-class="{
                                                'red_quantite' : ((vm.newbl.lignes[` + index + `].stock < vm.newbl.lignes[` + index + `].quantite ||
                                                                  vm.newbl.lignes[` + index + `].quantite<=0) && vm.global_gestion_stock == 1 ) ||
                                                                  (vm.use_quota==1 && vm.newbl.lignes[` + index + `].quota < vm.newbl.lignes[` + index + `].quantite)
                                            }"
                                            ng-keyup="
                                            vm.checkGratuiter(` + index + `, vm.newbl.lignes[` + index + `].produit_code, vm.newbl.lignes[` + index + `].quantite);
                                            ">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group text-center">
                                    <input type="text" class="form-control" disabled
                                        ng-class="{ 'red_quantite' : (vm.newbl.lignes[` + index + `].stock < vm.newbl.lignes[` + index + `].quantite || vm.newbl.lignes[` + index + `].quantite<=0) && vm.global_gestion_stock == 1 }"
                                        ng-model="vm.newbl.lignes[` + index + `].casier" >
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].prix_ht" required
                                            ng-keyup="vm.changeTtcFromHT(` + index + `);vm.recalculateLigne(` + index + `)">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].prix_ttc" required
                                            ng-keyup="vm.changeHtFromTTC(` + index + `);vm.recalculateLigne(` + index + `)">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].remise" required
                                            ng-keyup="vm.recalculateLigne(` + index + `)">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].total_ttc" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].total_net_ttc" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <i class="fa fa-minus iconred" ng-click="vm.removeBlock(` + index + `)"></i>
                            </div>
                            <div class="clearfix"></div>
                            <div class="row">
                                <div class="col-md-3 col-md-offset-1">
                                    <div class="form-group">
                                        <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].num_lot" placeholder="Numero de lot">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <datepicker date-format="yyyy-MM-dd" selector="input_date_` + index + `">
                                            <div class="input-group">
                                                <input class="form-control input_date_` + index + `" ng-model="vm.newbl.lignes[` + index + `].date_peremption"
                                                    placeholder="{{'Date Peremption'| translate}}" />
                                                    <span class="input-group-addon" style="cursor:pointer;padding:3px;">
                                                        <i class="fa fa-lg fa-calendar"></i>
                                                    </span>
                                            </div>
                                        </datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" id="block-grat-` + index + `"></div>
                        </div>`)($scope);
        vm.countblock = index + 1;
        $('#panelProduits').append(newBlock);
        vm.renewListProd();
    };

    vm.removeBlock = function (index) {
        if (vm.newbl.lignes != undefined && vm.newbl.lignes[index]) {
            var mod = _.omit($parse("vm.newbl.lignes")($scope), index);
            $parse("vm.newbl.lignes").assign($scope, mod);
            //remove gratuiter
            var mod = _.omit($parse("vm.newbl.gratuites")($scope), index);
            $parse("vm.newbl.gratuites").assign($scope, mod);
            vm.newbl.gratuites = $.map(vm.newbl.gratuites, function (value, index) {
                return [value];
            });
            // calcul total
            vm.newbl.totalRemiseTtc = 0;
            vm.newbl.totalTtc = 0;
            vm.newbl.totalNetTtc = 0;
            angular.forEach(vm.newbl.lignes, function (value, key) {
                vm.newbl.totalTtc = roundToThree(vm.newbl.totalTtc + value.total_ttc);
                vm.newbl.totalNetTtc = roundToThree(vm.newbl.totalNetTtc + value.total_net_ttc);
            });
            vm.newbl.totalRemiseTtc = roundToThree(vm.newbl.totalTtc - vm.newbl.totalNetTtc);
        }
        $('#block-' + index).remove();
        vm.renewListProd();
    };

    vm.getPuProduct = function (index, produit_code) {
        if (vm.newbl.distributeur_code) {
            WS.getData('produits/getPuProduct', {
                produit_code: produit_code,
                client_code: vm.newbl.client_code,
                depot_code: vm.newbl.depot_code,
                soussociete_code: vm.newbl.distributeur_code
            })
                .then(
                    function (response) {
                        vm.newbl.lignes[index].prix_ttc = response.data.prix_ttc;
                        vm.newbl.lignes[index].prix_ht = response.data.prix_ht;
                        vm.newbl.lignes[index].stock = response.data.stock;
                        vm.newbl.lignes[index].stock_carton = response.data.stock / response.data.colisage;
                        vm.newbl.lignes[index].stock_carton = vm.newbl.lignes[index].stock_carton.toFixed(2);
                        vm.newbl.lignes[index].colisage = response.data.colisage;
                        vm.newbl.lignes[index].tva = response.data.tva;
                        vm.newbl.lignes[index].prix_achat_ht = response.data.prix_achat_ht;
                        vm.newbl.lignes[index].prix_achat_ttc = response.data.prix_achat_ttc;
                        vm.newbl.lignes[index].quota = response.data.quota;
                        //console.log('before', vm.produits);

                        vm.renewListProd();
                        vm.recalculateLigne(index);
                        $scope.$apply();

                    },
                    function (error) {
                        console.log(error);
                    });
        } else {
            vm.newbl.lignes[index].produit_code = "";
            Flash.create('danger', $translate.instant("Sélectionner le distributeur avant de choisir les produits"));
        }
    };

    vm.renewListProd = () => {
        vm.produits = vm.stocked_lists_products;
        var draggedProducts = _.pluck(vm.newbl.lignes, 'produit_code');
        vm.produits = _.reject(vm.produits, function (num) {
            var i = 0;
            _.each(draggedProducts, element => {
                if (element == num.code) {
                    i++;
                }
            });
            if (i > 0) {
                return true;
            } else {
                return false;
            }
            // return !$.inArray(num.code, draggedProducts);
        });
        // console.log('resfinal', vm.produits);
        // console.log('draggedProducts', draggedProducts);
    }


    WS.getData('parametrages/getIndexForDashboard', vm.access)
        .then(function (response) {
            if (response.status == 200) {
                vm.parametrages = response.data;
                vm.use_quota = (response.data.use_quota) ? response.data.use_quota : 0;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });

    function roundToThree(num) {
        return +(Math.round(num + "e+3") + "e-3");
    };


    WS.getScache('pays').then(function (response) {
        vm.getpays = response.data;
        console.log('vm.getpays', vm.getpays)
        // vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
        // vm.zoom = AppSettings.zoomalgerie;
        $scope.$apply();
    }, function (error) {
        console.log(error);
    });

    /*
    '***********
    ' Devise=0   aucune
    '       =1   Dinar 
    '       =2   Dollar $
    ' Langue=0   Français
    '       =1   Belgique
    '       =2   Suisse
    '***********
    ' Conversion limitée à 999 999 999 999 999 ou 9 999 999 999 999,99
    ' si le nombre contient plus de 2 décimales, il est arrondit à 2 décimales
    */
    vm.ConvNumberLetter_fr = function (Nombre, bCheckFloat) {
        Nombre = (Nombre) ? Nombre.toFixed(3) : Nombre;
        var strNombre = new String(Nombre);
        var TabNombre = new Array();
        var strLetter = new String();
        if (isNaN(parseFloat(Nombre))) return "";
        if (bCheckFloat) {
            TabNombre = strNombre.split(".");
            if (TabNombre.length > 2 || TabNombre.length <= 0) return "";
            for (var i = 0; i < TabNombre.length; i++) {
                if (i == 0)
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 1, 0);
                else
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 0, 0);
            }
            //return strLetter + $translate.instant('Millimes');
            return strLetter + $translate.instant(vm.getpays.millimes);
            // strDev =
        } else {
            strLetter = ConvNumberLetter(Nombre, 1, 0);
            return strLetter;
        }
    }

    function ConvNumberLetter(Nombre, Devise, Langue) {
        var dblEnt, byDec;
        var bNegatif;
        var strDev = new String();
        var strCentimes = new String();

        if (Nombre < 0) {
            bNegatif = true;
            Nombre = Math.abs(Nombre);
        }
        dblEnt = parseInt(Nombre);
        byDec = parseInt((Nombre - dblEnt) * 100);
        if (byDec == 0) {
            if (dblEnt > 999999999999999) {
                return "#TropGrand";
            }
        } else {
            if (dblEnt > 9999999999999.99) {
                return "#TropGrand";
            }
        }
        switch (Devise) {
            case 0:
                if (byDec > 0) strDev = $translate.instant(" virgule");
                break;
            case 1:
                //strDev = " Dinar";
                strDev = vm.getpays.devise;
                if (byDec > 0) strCentimes = strCentimes + " Cents";
                break;
            case 2:
                strDev = $translate.instant(" Dollar");
                if (byDec > 0) strCentimes = strCentimes + " Cent";
                break;
        }
        if (dblEnt > 1 && Devise != 0) strDev = strDev + "s et";

        var NumberLetter = ConvNumEnt(parseFloat(dblEnt), Langue) + strDev + " " + ConvNumDizaine(byDec, Langue) + strCentimes;
        return NumberLetter;
    }

    function ConvNumEnt(Nombre, Langue) {
        var byNum, iTmp, dblReste;
        var StrTmp = new String();
        var NumEnt;
        iTmp = Nombre - (parseInt(Nombre / 1000) * 1000);
        NumEnt = ConvNumCent(parseInt(iTmp), Langue);
        dblReste = parseInt(Nombre / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = $translate.instant("mille ");
                break;
            default:
                StrTmp = StrTmp + $translate.instant(" mille ");
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + $translate.instant(" million ");
                break;
            default:
                StrTmp = StrTmp + $translate.instant(" millions ");
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + $translate.instant(" milliard ");
                break;
            default:
                StrTmp = StrTmp + $translate.instant(" milliards ");
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + $translate.instant(" billion ");
                break;
            default:
                StrTmp = StrTmp + $translate.instant(" billions ");
        }
        NumEnt = StrTmp + NumEnt;
        return NumEnt;
    }

    function ConvNumDizaine(Nombre, Langue) {
        var TabUnit, TabDiz;
        var byUnit, byDiz;
        var strLiaison = new String();

        TabUnit = Array("",
            $translate.instant("un"),
            $translate.instant("deux"), $translate.instant("trois"),
            $translate.instant("quatre"), $translate.instant("cinq"),
            $translate.instant("six"), $translate.instant("sept"),
            $translate.instant("huit"), $translate.instant("neuf"),
            $translate.instant("dix"), $translate.instant("onze"),
            $translate.instant("douze"), $translate.instant("treize"),
            $translate.instant("quatorze"), $translate.instant("quinze"),
            $translate.instant("seize"), $translate.instant("dix-sept"),
            $translate.instant("dix-huit"), $translate.instant("dix-neuf")
        );
        TabDiz = Array("", "",
            $translate.instant("vingt"), $translate.instant("trente"),
            $translate.instant("quarante"), $translate.instant("cinquante"),
            $translate.instant("soixante"), $translate.instant("soixante"),
            $translate.instant("quatre-vingt"), $translate.instant("quatre-vingt")
        );
        if (Langue == 1) {
            TabDiz[7] = $translate.instant("septante");
            TabDiz[9] = $translate.instant("nonante");
        } else if (Langue == 2) {
            TabDiz[7] = $translate.instant("septante");
            TabDiz[8] = $translate.instant("huitante");
            TabDiz[9] = $translate.instant("nonante");
        }
        byDiz = parseInt(Nombre / 10);
        byUnit = Nombre - (byDiz * 10);
        strLiaison = " "; //"-"
        if (byUnit == 1) strLiaison = $translate.instant(" et ");
        switch (byDiz) {
            case 0:
                strLiaison = "";
                break;
            case 1:
                byUnit = byUnit + 10;
                strLiaison = "";
                break;
            case 7:
                if (Langue == 0) byUnit = byUnit + 10;
                break;
            case 8:
                if (Langue != 2) strLiaison = " "; //"-"
                break;
            case 9:
                if (Langue == 0) {
                    byUnit = byUnit + 10;
                    strLiaison = " "; //"-"
                }
                break;
        }
        var NumDizaine = TabDiz[byDiz];
        if (byDiz == 8 && Langue != 2 && byUnit == 0) NumDizaine = NumDizaine + "s";
        if (TabUnit[byUnit] != "") {
            NumDizaine = NumDizaine + strLiaison + TabUnit[byUnit];
        } else {
            NumDizaine = NumDizaine;
        }
        return NumDizaine;
    }

    function ConvNumCent(Nombre, Langue) {
        var TabUnit;
        var byCent, byReste;
        var strReste = new String();
        var NumCent;
        TabUnit = Array("",
            $translate.instant("un"),
            $translate.instant("deux"), $translate.instant("trois"),
            $translate.instant("quatre"), $translate.instant("cinq"),
            $translate.instant("six"), $translate.instant("sept"),
            $translate.instant("huit"), $translate.instant("neuf"),
            $translate.instant("dix")
        );

        byCent = parseInt(Nombre / 100);
        byReste = Nombre - (byCent * 100);
        strReste = ConvNumDizaine(byReste, Langue)
        switch (byCent) {
            case 0:
                NumCent = strReste;
                break;
            case 1:
                if (byReste == 0)
                    NumCent = "cent";
                else
                    NumCent = "cent " + strReste;
                break;
            default:
                if (byReste == 0)
                    NumCent = TabUnit[byCent] + " cents";
                else
                    NumCent = TabUnit[byCent] + " cent " + strReste;
        }
        return NumCent;
    }

    vm.manageFactures = function (code, remiseEspece, ttc, net) {

        if (vm.managefacture[code] == true && $.inArray(code, vm.tabsFacture) == -1) {
            vm.tabsFacture.bls.push(code);
            vm.tabsFacture.remiseEspece = roundToThree(vm.tabsFacture.remiseEspece + remiseEspece);
            vm.tabsFacture.ttc = roundToThree(vm.tabsFacture.ttc + ttc);
            vm.tabsFacture.net = roundToThree(vm.tabsFacture.net + net);
        } else if (vm.managefacture[code] == false) {
            vm.tabsFacture.bls.splice($.inArray(code, vm.tabsFacture), 1);
            vm.tabsFacture.remiseEspece = roundToThree(vm.tabsFacture.remiseEspece - remiseEspece);
            vm.tabsFacture.ttc = roundToThree(vm.tabsFacture.ttc - ttc);
            vm.tabsFacture.net = roundToThree(vm.tabsFacture.net - net);

        }
        vm.tabsFacture.remise = roundToThree(vm.tabsFacture.ttc - net);
    };

    vm.storeFactures = function () {
        if (typeof vm.tabsFacture.client_code == "undefined") {
            Flash.create('danger', $translate.instant("Veuillez bien selectionner le client!"));
            return;
        }
        if (vm.tabsFacture.bls.length == 0) {
            Flash.create('danger', $translate.instant("Veuillez choisir au moins une bl!"));
            return;
        };

        WS.post('entetecommercial/upgradeBLToFacture', vm.tabsFacture)
            .then(function (response) {
                $state.go("app.facture.details", { id: response.data.code });
                $('.modal-backdrop').remove();
                $scope.$apply();
            }, function (error) {
                console.log(error);
            });
    };


    /**
     * calculate HT from ttc
     */

    vm.changeHtFromTTC = (index) => {
        //$value['pu_ht'] = round($value['pu_ttc'] / (1 + ($value['taux_tva'] / 100)), 3);
            vm.newbl.lignes[index].prix_ht = vm.newbl.lignes[index].prix_ttc / (1 + (vm.newbl.lignes[index].tva / 100))
            vm.newbl.lignes[index].prix_ht = parseFloat(vm.newbl.lignes[index].prix_ht).toFixed(3);
            vm.newbl.lignes[index].prix_ht = Number(vm.newbl.lignes[index].prix_ht);
    }

    vm.changeTtcFromHT = (index) => {
        vm.newbl.lignes[index].prix_ttc = Number(vm.newbl.lignes[index].prix_ht) + (Number(vm.newbl.lignes[index].prix_ht) * Number(vm.newbl.lignes[index].tva) / 100);
        vm.newbl.lignes[index].prix_ttc = vm.newbl.lignes[index].prix_ttc.toFixed(3);
        vm.newbl.lignes[index].prix_ttc = Number(vm.newbl.lignes[index].prix_ttc);
    }

    vm.recalculateLigne = (index) => {

        //vm.newbl.lignes[index].remise = response.data.remise;
        vm.newbl.lignes[index].total_ttc = (vm.newbl.lignes[index].quantite) ? roundToThree(vm.newbl.lignes[index].quantite * vm.newbl.lignes[index].prix_ttc) : 0;
        vm.newbl.lignes[index].total_net_ttc = roundToThree(vm.newbl.lignes[index].total_ttc - ((vm.newbl.lignes[index].remise * vm.newbl.lignes[index].total_ttc) / 100));
        vm.newbl.lignes[index].total_ht = (vm.newbl.lignes[index].quantite) ? roundToThree(vm.newbl.lignes[index].quantite * vm.newbl.lignes[index].prix_ht) : 0;
        vm.newbl.lignes[index].total_net_ht = roundToThree(vm.newbl.lignes[index].total_ht - ((vm.newbl.lignes[index].remise * vm.newbl.lignes[index].total_ht) / 100));

        vm.newbl.lignes[index].p_tva = roundToThree(((vm.newbl.lignes[index].quantite * vm.newbl.lignes[index].prix_ht) -
            ((vm.newbl.lignes[index].remise / 100) * (vm.newbl.lignes[index].quantite * vm.newbl.lignes[index].prix_ht))
        ) * (vm.newbl.lignes[index].tva / 100));

        // calcul total
        vm.newbl.totalRemiseTtc = 0;
        vm.newbl.totalTtc = 0;
        vm.newbl.totalNetTtc = 0;

        vm.newbl.totalRemiseHt = 0;
        vm.newbl.totalHt = 0;
        vm.newbl.totalNetHt = 0;

        vm.newbl.totalTva = 0;

        angular.forEach(vm.newbl.lignes, function (value, key) {
            // TTC
            vm.newbl.totalTtc = roundToThree(vm.newbl.totalTtc + value.total_ttc);
            vm.newbl.totalNetTtc = roundToThree(vm.newbl.totalNetTtc + value.total_net_ttc);
            // HT
            vm.newbl.totalHt = roundToThree(vm.newbl.totalHt + value.total_ht);
            vm.newbl.totalNetHt = roundToThree(vm.newbl.totalNetHt + value.total_net_ht);

            //tva
            vm.newbl.totalTva = roundToThree(vm.newbl.totalTva + value.p_tva);

        });
        vm.newbl.totalRemiseHt = roundToThree(vm.newbl.totalHt - vm.newbl.totalNetHt);
        vm.newbl.totalRemiseTtc = roundToThree(vm.newbl.totalTtc - vm.newbl.totalNetTtc);
    }

    // vm.checkGratuiter = function (index, produit_code, quantite) {
    //     if (quantite > 0) {
    //         var url = 'produits/checkRemise/' + produit_code + '/' + quantite;
    //     } else {
    //         var url = 'produits/checkRemise/' + produit_code + '/' + 0;
    //     }
    //     // check quota
    //     vm.pass_quota_error = 0;
    //     if(vm.use_quota==1){
    //         console.log("check quota")

    //         _.each(vm.newbl.lignes , element => {
    //             if(Number(element.quota) < Number(element.quantite)){
    //                 vm.pass_quota_error = vm.pass_quota_error+1;
    //                 console.log(element.quota,' < ',element.quantite)
    //             }
    //         })
    //     }

    //     WS.get(url).then(
    //         function (response) {
    //             vm.newbl.lignes[index].casier = roundToThree(vm.newbl.lignes[index].quantite / vm.newbl.lignes[index].colisage);
    //             vm.newbl.lignes[index].remise = response.data.remise;
    //             vm.recalculateLigne(index);
    //             // vm.newbl.lignes[index].ttc = (vm.newbl.lignes[index].quantite) ? roundToThree(vm.newbl.lignes[index].quantite * vm.newbl.lignes[index].prix_ttc) : 0;
    //             // vm.newbl.lignes[index].net = roundToThree(vm.newbl.lignes[index].ttc - ((vm.newbl.lignes[index].remise * vm.newbl.lignes[index].ttc) / 100));
    //             // // calcul total
    //             // vm.newbl.totalRemise = 0;
    //             // vm.newbl.totalTtc = 0;
    //             // vm.newbl.totalNet = 0;
    //             // angular.forEach(vm.newbl.lignes, function(value, key) {
    //             //     vm.newbl.totalTtc = roundToThree(vm.newbl.totalTtc + value.ttc);
    //             //     vm.newbl.totalNet = roundToThree(vm.newbl.totalNet + value.net);
    //             // });
    //             // vm.newbl.totalRemise = roundToThree(vm.newbl.totalTtc - vm.newbl.totalNet);

    //             $scope.$apply();


    //             if (quantite > 0) {
    //                 var url = 'produits/checkGratuiter/' + produit_code + '/' + quantite + '/' + vm.newbl.lignes[index].total_net_ttc;
    //             } else {
    //                 var url = 'produits/checkGratuiter/' + produit_code + '/' + 0 + '/' + vm.newbl.lignes[index].total_net_ttc;
    //             }

    //             WS.get(url).then(
    //                 function (response) {
    //                     if (vm.newbl.gratuites && vm.newbl.gratuites.length > 0 && vm.newbl.gratuites[index]) {
    //                         var mod = _.omit($parse("vm.newbl.gratuites")($scope), index);
    //                         $parse("vm.newbl.gratuites").assign($scope, mod);
    //                         vm.newbl.gratuites = $.map(vm.newbl.gratuites, function (value) {
    //                             return [value];
    //                         });
    //                     }

    //                     vm.temporary_products = response.data;
    //                     if (response.data.length > 0) {
    //                         var alert = `<div class="alert alert-success" role="alert">

    //                               <h4>` + $translate.instant('Produit(s) gratuit(s)') + ` : </h4>`;

    //                         if (response.data.length == 1) {
    //                             vm.newbl.gratuites[index] = response.data;
    //                             response.data.forEach(function (element) {
    //                                 alert += `
    //                                 <div id="grat-prod-${index}-${element.produit_code}">
    //                                     <div class="clearfix"></div>
    //                                     <div>
    //                                         <div class="pull-left">
    //                                             <i class="fa fa-minus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index}, -1);"
    //                                                style="color: red;font-size: 18px;margin-top: 2px;margin-left: 10px;margin-right: 7px;cursor: pointer;"></i>
    //                                         </div>
    //                                         <div class="pull-left" ng-init="vm.quantites['${index}-${element.produit_code}'].quantite=${element.quantite}">
    //                                             <input type="number" class="text-center"
    //                                                    ng-model="vm.quantites['${index}-${element.produit_code}'].quantite"
    //                                                    style="width: 49px;font-size: 13px;margin-top: -2px;">
    //                                         </div>
    //                                         <div class="pull-left">
    //                                             <i class="fa fa-plus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index}, 1);"
    //                                                 style="color: blue;font-size: 18px;margin-top: 2px;margin-left: 5px;margin-right: 12px;cursor: pointer;"></i>
    //                                         </div>
    //                                         <h4 class="pull-left" style="margin-top: 3px;">${element.produit_code} ${element.gratuit_libelle} </h4>
    //                                         <div class="pull-left">
    //                                             <i class="fa fa-trash" ng-click="vm.removeGratuite('${element.produit_code}', ${index});"
    //                                                 style="color: red;font-size: 18px;margin-top: 1px;margin-left: 10px;cursor: pointer;"></i>
    //                                         </div>
    //                                      </div>
    //                                      <div class="clearfix"></div>
    //                                 </div>`;
    //                             });
    //                             alert += `</div>`;
    //                             alert = $compile(alert)($scope);
    //                             $('#block-grat-' + index).empty();
    //                             $('#block-grat-' + index).append(alert);
    //                         } else {
    //                             //panel shoose products
    //                             alert += `<h5 style="color:red!important;">
    //                                             ${$translate.instant('Vous avez plusieurs produits gratuits')}
    //                                             <span style="color:blue!important;cursor:pointer;"
    //                                                   ng-click="vm.showPanelProduitGratuit(${index});"> ${$translate.instant('cliquez ici')}  </span>
    //                                             ${$translate.instant('pour sélectionner')}
    //                                       </h5>
    //                                 </div>`
    //                             alert = $compile(alert)($scope);
    //                             $('#block-grat-' + index).empty();
    //                             $('#block-grat-' + index).append(alert);
    //                         }

    //                     } else {
    //                         $('#block-grat-' + index).empty();
    //                     }
    //                     console.log('checkGratuiter',vm.newbl.gratuites);




    //                     // vm.newbl.gratuites = vm.newbl.gratuites.filter(function(el) {
    //                     //     console.log(el,  _.isArray(el));
    //                     //     return _.isArray(el);
    //                     // });
    //                     console.log('resultGratuite',vm.newbl.gratuites);

    //                     $scope.$apply();
    //                 },
    //                 function (error) {
    //                     console.log(error);
    //                 });
    //         },
    //         function (error) {
    //             console.log(error);
    //         });
    // };


    vm.checkGratuiter = function (index, produit_code, quantite) {
        if (quantite > 0) {
            var url = 'produits/checkRemise/' + produit_code + '/' + quantite;
        } else {
            var url = 'produits/checkRemise/' + produit_code + '/' + 0;
        }
        // check quota
        vm.pass_quota_error = 0;
        if (vm.use_quota == 1) {
            console.log("check quota")

            _.each(vm.newbl.lignes, element => {
                if (Number(element.quota) < Number(element.quantite)) {
                    vm.pass_quota_error = vm.pass_quota_error + 1;
                    console.log(element.quota, ' < ', element.quantite)
                }
            })
        }

        WS.getData(url).then(
            function (response) {
                vm.newbl.lignes[index].casier = roundToThree(vm.newbl.lignes[index].quantite / vm.newbl.lignes[index].colisage);
                vm.newbl.lignes[index].remise = response.data.remise;
                vm.recalculateLigne(index);

                $scope.$apply();


                var data = {
                    produit_code,
                    quantite: (quantite > 0) ? quantite : 0,
                    net_a_payer: vm.newbl.lignes[index].total_net_ttc,
                    allprods: vm.newbl.lignes,
                    soussociete_code: vm.newbl.distributeur_code
                }

                var url = 'produits/checkGratuiterPost';
                WS.post(url, data).then(
                    function (response) {
                        console.log('response', response);
                        if (vm.newbl.gratuites && vm.newbl.gratuites.length > 0 && vm.newbl.gratuites[index]) {
                            var mod = _.omit($parse("vm.newbl.gratuites")($scope), index);
                            $parse("vm.newbl.gratuites").assign($scope, mod);
                            vm.newbl.gratuites = $.map(vm.newbl.gratuites, function (value) {
                                return [value];
                            });
                        }

                        vm.temporary_products = response.data;
                        if (response.data.length > 0) {
                            var alert = `<div class="alert alert-success" role="alert">

                                  <h4>` + $translate.instant('Produit(s) gratuit(s)') + ` : </h4>`;

                            if (response.data.length == 1) {
                                vm.newbl.gratuites[index] = response.data;
                                response.data.forEach(function (element, ind) {
                                    alert += `
                                    <div id="grat-prod-${index}-${element.produit_code}-${ind}">
                                        <div class="clearfix"></div>
                                        <div>
                                            <div class="pull-left">
                                                <i class="fa fa-minus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index}, -1, ${ind});"
                                                   style="color: red;font-size: 18px;margin-top: 2px;margin-left: 10px;margin-right: 7px;cursor: pointer;"></i>
                                            </div>
                                            <div class="pull-left" ng-init="vm.quantites['${index}-${element.produit_code}-${ind}'].quantite=${element.quantite}">
                                                <input type="number" class="text-center"
                                                       ng-model="vm.quantites['${index}-${element.produit_code}-${ind}'].quantite"
                                                       style="width: 49px;font-size: 13px;margin-top: -2px;">
                                            </div>
                                            <div class="pull-left">
                                                <i class="fa fa-plus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index}, 1, ${ind});"
                                                    style="color: blue;font-size: 18px;margin-top: 2px;margin-left: 5px;margin-right: 12px;cursor: pointer;"></i>
                                            </div>
                                            <h4 class="pull-left" style="margin-top: 3px;">${element.produit_code} ${element.gratuit_libelle} </h4>
                                            <div class="pull-left">
                                                <i class="fa fa-trash" ng-click="vm.removeGratuite('${element.produit_code}', ${index}, ${ind});"
                                                    style="color: red;font-size: 18px;margin-top: 1px;margin-left: 10px;cursor: pointer;"></i>
                                            </div>
                                         </div>
                                         <div class="clearfix"></div>
                                    </div>`;
                                });
                                alert += `</div>`;
                                alert = $compile(alert)($scope);
                                $('#block-grat-' + index).empty();
                                $('#block-grat-' + index).append(alert);
                            } else {
                                //panel shoose products
                                alert += `<h5 style="color:red!important;">
                                                ${$translate.instant('Vous avez plusieurs produits gratuits')}
                                                <span style="color:blue!important;cursor:pointer;"
                                                      ng-click="vm.showPanelProduitGratuit(${index});"> ${$translate.instant('cliquez ici')}  </span>
                                                ${$translate.instant('pour sélectionner')}
                                          </h5>
                                    </div>`
                                alert = $compile(alert)($scope);
                                $('#block-grat-' + index).empty();
                                $('#block-grat-' + index).append(alert);
                            }

                        } else {
                            $('#block-grat-' + index).empty();
                        }
                        console.log('checkGratuiter', vm.newbl.gratuites);




                        // vm.newbl.gratuites = vm.newbl.gratuites.filter(function(el) {
                        //     console.log(el,  _.isArray(el));
                        //     return _.isArray(el);
                        // });
                        console.log('resultGratuite', vm.newbl.gratuites);

                        $scope.$apply();
                    },
                    function (error) {
                        console.log(error);
                    });
            },
            function (error) {
                console.log(error);
            });
    };
    vm.showPanelProduitGratuit = (index_gratuites) => {
        console.log(index_gratuites, vm.temporary_products);
        vm.panel_produit_gratuits = $uibModal.open({
            animation: true,
            templateUrl: 'panel_produit_gratuits',
            size: 'lg',
            backdrop: false,
            windowClass: 'zindex lg-95',
            controller: function ($scope, $translate) {
                $scope.produits = vm.temporary_products;
                $scope.global_gestion_stock = vm.global_gestion_stock;

                _.each($scope.produits, (element, indexprod) => {
                    var i = 0;
                    _.each(vm.newbl.gratuites[index_gratuites], (gratuite, indexgrat) => {
                        if (gratuite.produit_code == element.produit_code && indexprod == indexgrat) {
                            i++;
                            element.gratuite = true;
                        }
                    });
                    if (i == 0) {
                        element.gratuite = false;
                    }

                    /**
                     * check stock
                     */
                    if ($scope.global_gestion_stock == 1) {
                        element.hors_stock = true;
                    }
                });

                $scope.closePanel = () => {
                    vm.panel_produit_gratuits.close({});
                }

                $scope.addGratuites = () => {
                    vm.submitAddGratuite($scope.produits, index_gratuites);
                };


            }
        });
    }

    vm.submitAddGratuite = (produits, index_gratuites) => {
        var $products = _.filter(produits, function (element) {
            if (vm.global_gestion_stock == 1) {
                if (element.quantite_stock >= element.quantite && element.gratuite == true) {
                    return true;
                }
            } else {
                return element.gratuite == true;

            }
        });
        vm.newbl.gratuites[index_gratuites] = $products;

        vm.listofProdGratuite = '';
        vm.listofProdGratuite = `<div class="alert alert-success" role="alert">
                                    <h4>` + $translate.instant('Produit(s) gratuit(s)') + ` : </h4>
                                    <h5 style="color:red!important;">
                                        ${$translate.instant('Vous avez plusieurs produits gratuits')}
                                        <span style="color:blue!important;cursor:pointer;"
                                                ng-click="vm.showPanelProduitGratuit(${index_gratuites});"> ${$translate.instant('cliquez ici')}  </span>
                                        ${$translate.instant('pour sélectionner')}
                                    </h5>`;
        console.log('each vm.newbl.gratuites[index_gratuites]', index_gratuites, vm.newbl.gratuites[index_gratuites])

        vm.newbl.gratuites[index_gratuites].forEach(function (element, ind) {
            console.log('each vm.newbl.gratuites[index_gratuites]', element, ind)
            vm.listofProdGratuite += `
            <div id="grat-prod-${index_gratuites}-${element.produit_code}-${ind}">
                <div class="clearfix"></div>
                <div>
                    <div class="pull-left">
                        <i class="fa fa-minus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index_gratuites}, -1, ${ind});"
                           style="color: red;font-size: 18px;margin-top: 2px;margin-left: 10px;margin-right: 7px;cursor: pointer;"></i>
                    </div>
                    <div class="pull-left" ng-init="vm.quantites['${index_gratuites}-${element.produit_code}-${ind}'].quantite=${element.quantite}">
                        <input type="number" class="text-center"
                               ng-model="vm.quantites['${index_gratuites}-${element.produit_code}-${ind}'].quantite"
                               style="width: 49px;font-size: 13px;margin-top: -2px;">
                    </div>
                    <div class="pull-left">
                        <i class="fa fa-plus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index_gratuites}, 1, ${ind});"
                            style="color: blue;font-size: 18px;margin-top: 2px;margin-left: 5px;margin-right: 12px;cursor: pointer;"></i>
                    </div>
                    <h4 class="pull-left" style="margin-top: 3px;">${element.produit_code} ${element.gratuit_libelle} </h4>
                    <div class="pull-left">
                        <i class="fa fa-trash" ng-click="vm.removeGratuite('${element.produit_code}', ${index_gratuites}, ${ind});"
                            style="color: red;font-size: 18px;margin-top: 1px;margin-left: 10px;cursor: pointer;"></i>
                    </div>
                 </div>
                 <div class="clearfix"></div>
            </div>`;
        });
        vm.listofProdGratuite += '</div>';
        vm.listofProdGratuite = $compile(vm.listofProdGratuite)($scope);
        $('#block-grat-' + index_gratuites).empty();
        $('#block-grat-' + index_gratuites).append(vm.listofProdGratuite);
        vm.panel_produit_gratuits.close({});
    };
    vm.removeGratuite = (produit_code, index, index_prod) => {

        console.log('params', produit_code, index, index_prod);
        console.log('beforeREmove', vm.newbl.gratuites);
        vm.newbl.gratuites[index] = $.grep(vm.newbl.gratuites[index], function (el, idx) { return el.produit_code == produit_code && idx == index_prod }, true);
        $('#grat-prod-' + index + '-' + produit_code + '-' + index_prod).remove();
        if (vm.newbl.gratuites[index].length == 0) {
            vm.newbl.gratuites = _.reject(vm.newbl.gratuites, function (num, ind) { return ind == index; });
            $('#block-grat-' + index).empty();
        }
        console.log('afterREmove', vm.newbl.gratuites);

    };

    vm.updateQteGratuite = (produit_code, index, $inc, index_prod) => {

        _.each(vm.newbl.gratuites[index], (element, ind) => {
            if (element.produit_code == produit_code && ind == index_prod) {
                element.quantite = element.quantite + ($inc);
                element.quantite = (element.quantite < 1) ? 1 : element.quantite;
                vm.quantites[index + '-' + produit_code + '-' + index_prod].quantite = element.quantite;
            }

        });
    };

    function dataGrid_DevExpress() {
        var collapsed = false;
        vm.dataGridOptions = {
            dataSource: vm.bls,
            paging: {
                pageSize: 10
            },
            pager: {
                showPageSizeSelector: true,
                allowedPageSizes: [10, 25, 50, 100]
            },
            remoteOperations: false,
            searchPanel: {
                visible: true,
                highlightCaseSensitive: true
            },
            groupPanel: { visible: true },
            grouping: {
                autoExpandAll: false
            },
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            showBorders: true,
            columns: [{
                dataField: "user",
                groupIndex: 0
            },
            {
                dataField: "code",
                dataType: "string"
            },
            {
                dataField: "user",
                dataType: "string"
            },
            {
                dataField: "depot",
                dataType: "string",
            },
            {
                dataField: "soussociete",
                dataType: "string",
            },
            {
                dataField: "client",
                dataType: "string",
                width: 150
            },
            {
                dataField: "remise",
                dataType: "string",
            },
            {
                dataField: "remiseEspece",
                dataType: "string",
            },
            {
                dataField: "montant_total_ttc",
                dataType: "string",
            },
            {
                dataField: "net_a_payer",
                dataType: "string",
            },
            {
                dataField: "date",
                dataType: "date",
            }
            ],
            onContentReady: function (e) {
                if (!collapsed) {
                    collapsed = true;
                    e.component.expandRow(["EnviroCare"]);
                }
            }
        };
    };

    vm.customizeTooltip = function (pointsInfo) {
        return { text: parseInt(pointsInfo.originalValue) + "%" };
    };

    vm.deleteBl_all = function (code, user) {
        //console.log(code);
        //console.log(user);
        swal({
            title: $translate.instant(`Suppression`),
            // text: $translate.instant('code bl') + ` : `,
            html: `${$translate.instant('Vos étes sur le point de supprimer le bl ainsi que leurs mouvements, visites et paiements')}
                    <h4 class="text-center">${code}</h4>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, supprimer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'VerificationOfPassword',
                size: 'md',
                backdrop: true,
                controller: function ($scope, $translate) {
                    $scope.message_password = $translate.instant('confirmer le mot de passe');
                    $scope.current_numero = code;

                    $scope.deleteBl = function () {
                        if ($scope.set_password != '' && $scope.set_password != undefined) {
                            var data = {
                                numero: $scope.current_numero,
                                password: $scope.set_password
                            };
                            WS.post('entetecommercial/deleteFromDash', data).then(
                                function (response) {
                                    if (response.data.success) {
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Supprimée'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        }).catch(function () {
                                            $window.location.reload();
                                        });;
                                    } else {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant(response.data.message),
                                            'warning'
                                        );
                                    }
                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('erreur de chargement des données'),
                                        'warning'
                                    );
                                }
                            );
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Mot de passe Obligatoire'),
                                'warning'
                            );
                        }
                    }
                }
            });

        });

    };

    vm.getTickets = () => {
        WS.get('ticket_cadeaux').then(
            (response) => {
                vm.ticket_cadeaux = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };
    vm.getResultsTickets = () => {
        vm.newbl.ticket_cadeau_resultat = 0;
        if (vm.newbl.ticket_cadeau_type && vm.newbl.ticket_cadeau_type != "") {
            const current_ticket = _.find(vm.ticket_cadeaux, item => item.type == vm.newbl.ticket_cadeau_type);
            const montant = current_ticket.montant;
            const pourcentage = current_ticket.pourcentage;
            const quantite = vm.newbl.ticket_cadeau_quantite;
            const total = Number(quantite) * parseFloat(montant);
            const commission = (total * parseFloat(pourcentage)) / 100;

            vm.newbl.ticket_cadeau_idTicket = current_ticket.id;
            vm.newbl.ticket_cadeau_resultat = total - commission;
            //vm.newbl.espece = total - commission;
            //console.log(vm.ticket_cadeaux, vm.newbl.ticket_cadeau_type, current_ticket, vm.newbl.ticket_cadeau_quantite);
        }
    };


    vm.getParametrage = () => {
        WS.get('parametrages/getIndexForDashboard').then(
            (response) => {
                vm.parametrages = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };


    vm.PhenixUpdateEntetes = (code) => {
      WS.getData('nomadisIntegration/PhenixUpdateEntetes', {code}).then(
          (response) => {
          //   {
          //     "result": [
          //         {
          //             "result": "error",
          //             "value": "لا يمكن اجراء اي عملية قبل  تاريخ بداية السنة المالية 01/01/2025"
          //         }
          //     ]
          // }
              vm.responsePhenix = response.data;
              console.log(vm.responsePhenix)

              var html = ``;
              _.each(vm.responsePhenix, (element, index) => {

                var prods = ``;
                _.each(element.produits, (produit, indexProd) => {
                  // $billdetail->unitid =  1;
                  // $billdetail->itemprice =  $ligne->pu_ttc;
                  // $billdetail->itemid =  $ligne->produit_code_erp;
                  // $billdetail->discountvalue =  10;
                  // $billdetail->quantity =  $ligne->quantite;
                    prods += `
                      <div><b>code erp : ${produit.itemid} | quantite : ${produit.quantity}  </b></div>

                    `;
                })
                html += `
                    <div><b>Code BL : ${element.code}</b></div>
                    <div><b>Code ERP Client : ${element.client_code}</b></div>
                    <div><b>Code ERP Commercial : ${element.commercial_code}</b></div>
                    <div style="color:red"><b>Message : ${element[0].result}  : ${element[0].value}</b></div>
                    <div><b>Produits :  </b></div>
                    ${prods}
                    <div><b>----------------------------------------</b><div>
                `
              })
              swal({
                title: $translate.instant(`Envoie de bl vers Phenix`),
                // text: $translate.instant('code bl') + ` : `,
                html: html,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: $translate.instant('OK!'),
                cancelButtonText: $translate.instant('Non, Annuler!'),
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false
            });

              $scope.$apply();
          },
          (error) => {
              console.log(error);
          }
      );
  };
    vm.selectListClientByPaginate = (page = 1, per_page = 15) => {
        var data = {
            page,
            per_page,
            searchbox: vm.searchbox
        };
        WS.getData('clients/selectListClientByPaginate', data)
            .then((response) => {
                vm.list_clients = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = response.data.per_page;
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }
                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error) => {

            })
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }


    vm.getCreditAndPlafondByDepot = (depot_code) => {
        WS.getData('depots/getCreditAndPlafondByDepot', { depot_code }).then(
            (response) => {
                vm.plafond_depot = response.data;

                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    }



    vm.sendMailsAutomatically = (type, code) => {

        var type_gamme = type + '_gammes';

        WS.getData('commandes/sendMailsAutomatically/' + type + '/' + code).then(
            (response) => {
                //swal('succés', 'Email envoyé avec succés', 'success');
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
        WS.getData('commandes/sendMailsAutomaticallyGammes/' + type_gamme + '/' + code).then(
            (response) => {
                swal('succés', 'Email envoyé avec succés', 'success');
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );

    }


    vm.openModalActions = (type, code) => {
        vm.selected_type = type;
        vm.selected_code = code;
        vm.panel_actions = $uibModal.open({
            animation: true,
            templateUrl: 'modalAddReglement',
            size: 'lg',
            backdrop: true,
            controller: function ($scope, $translate) {

                $scope.selected_type = vm.selected_type;
                $scope.selected_code = vm.selected_code;



                $scope.sendMailsAutomatically = () => {
                    //console.log('sendemailmanually')

                    WS.getData('commandes/sendMailsAutomatically/' + $scope.selected_type + '/' + $scope.selected_code).then(
                        (response) => {
                            //console.log('response sendemailmanually')

                            //  swal('succés', 'Email envoyé avec succès', 'success');

                            //console.log('swal sendemailmanually')

                            vm.panel_actions.close();

                            //console.log('close panel sendemailmanually')

                            $scope.$apply();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );

                    $scope.type_gamme = $scope.selected_type + '_gammes';

                    WS.getData('commandes/sendMailsAutomaticallyGammes/' + $scope.type_gamme + '/' + $scope.selected_code).then(
                        (response) => {
                            swal('succés', 'Email envoyé avec succés', 'success');
                            vm.panel_actions.close();
                            $scope.$apply();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );


                }

                $scope.SageExportBlByOne = () => {

                    WS.getData('commandes/SageExportBlByOne/' + $scope.selected_code).then(
                        (response) => {

                            swal('succés', 'BL envoyé avec succès', 'success');


                            vm.panel_actions.close();


                            $scope.$apply();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }

            }
        });
    }


    vm.sendToMerbiss = (entetecommercial_code) => {

        if(vm.merbiss.pdv.code){
            var data = {
                entetecommercial_code: entetecommercial_code,
                pdv_code : vm.merbiss.pdv.code,
                erp: vm.merbiss.pdv.erp,
                adresse_livraison : vm.merbiss.pdv.adresse_livraison
                // 'p.code','p.code_a_barre', 'p.erp', 'p.nompdv', 'p.nom_bc', 'p.adresse', 'p.adresse_livraison',
                // 'd.code as distributeur_code', 'd.code_erp as code_erp_distributeur','d.libelle as distributeur'
            }
            swal({
                title: $translate.instant(`Sync Nomadis Merbiss`),
                // text: $translate.instant('code bl') + ` : `,
                html: `${$translate.instant('Vos étes sur le point d\'envoyer le bl vers merbiss')}
                        <h4 class="text-center">${entetecommercial_code}</h4>`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: $translate.instant('Oui, Envoyer!'),
                cancelButtonText: $translate.instant('Non, Annuler!'),
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false
            }).then(function () {

                WS.post('entetecommercial/sendToMerbiss', data).then(
                    function (response) {
                        if (response.data.success) {
                            swal(
                                $translate.instant('Succès'),
                                $translate.instant('Bl envoyé avec Succès'),
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            }).catch(function () {
                                $window.location.reload();
                            });;
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant(response.data.message),
                                'warning'
                            );
                        }
                    },
                    function () {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant('erreur de chargement des données'),
                            'warning'
                        );
                    }
                );

            });
        }else{
            swal(
                $translate.instant('Oups!!'),
                $translate.instant('Veuillez bien selectionner un Pdv'),
                'warning'
            )
        }

    };


    vm.merbiss = {};
    vm.getPdvsMerbiss = () => {
        WS.getData('entetecommercial/getPdvsMerbiss').then(
            (response) => {
                vm.pdvs_merbiss = response.data.pdvs;
                vm.distributeur_merbiss = response.data.distributeurs;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    }


    vm.printElement = function (header, body, footer) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                        .pull-right{float:right;}
                                        .pull-left{float:left;}
                                        .flex_container {
                                            display: flex;
                                            justify-content: space-between;
                                        }

                                        .flex_container>div {
                                            width: 30%;
                                            margin: 10px;
                                        }
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        if (header) {
            mywindow.document.write(document.getElementById(header).innerHTML);
        }
        mywindow.document.write(document.getElementById(body).innerHTML);
        if (footer) {
            mywindow.document.write(document.getElementById(footer).innerHTML);
        }
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

}
export default {
    name: 'blController',
    fn: blController
};
